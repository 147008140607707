footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 12px;

  text-align: center;

  padding-top: 16px;
  padding-bottom: 16px;
}

.footer-social-icons {
  display: flex;
  gap: 16px;
}

.footer-social-icons > a {
  color: #fdc43f;
}
