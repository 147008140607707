.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  gap: 64px;
}

.home-header,
.home-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 48px;
  max-width: 320px;
}

.home-header {
  min-height: 72vh;
}

.home-section {
  min-height: 96vh;
  margin-top: 80px;
}

.home-mini-section {
  min-height: 80vh;
}

.home-section-image {
  max-height: 320px;
}

.home-header-image {
  width: 100%;
  height: 100%;
}

.home-article {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.home-cta-button {
  padding: 8px 16px;
  font-size: 16px;
}

.home-cards-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.home-card {
  display: flex;
  flex-direction: column;
}

.home-card-image {
  height: 160px;
  object-fit: cover;
}

.home-price-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.small-screen-only {
  display: flex !important;
}

.grid-small-screen-only {
  display: grid !important;
}

.large-screen-only {
  display: none !important;
}

/* MEDIA QUERIES */

/* iPads, Tablets */
@media only screen and (min-width: 480px) {
  .home-container {
    padding-left: 36px;
    padding-right: 36px;
  }

  .home-header,
  .home-section {
    max-width: 512px;
  }

  .home-article {
    gap: 24px;
  }

  .home-section-image {
    max-height: 320px;
  }

  .home-cta-button {
    padding: 12px 24px;
    font-size: 20px;
  }

  .home-card-image {
    height: 192px;
    object-fit: unset;
  }

  .home-price-list {
    gap: 20px;
  }
}

/* Small screens and laptops */
@media only screen and (min-width: 768px) {
  .home-header,
  .home-section {
    max-width: 640px;
  }

  .home-section-image {
    max-height: 384px;
  }

  .home-article {
    max-width: 640px;
  }

  .home-card-image {
    height: 256px;
  }

  .home-price-list {
    gap: 24px;
  }
}

/* Regular laptops, desktops, large screens */
@media only screen and (min-width: 1024px) {
  .home-container {
    gap: 32px;
  }

  .home-header {
    gap: 16px;
  }

  .home-header,
  .home-section {
    flex-direction: row;
    max-width: 952px;
    margin-top: 0px;
  }

  .home-article {
    align-items: flex-start;
  }

  .home-cards-list {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }

  .home-card-image {
    object-fit: cover;
  }

  .small-screen-only {
    display: none !important;
  }

  .grid-small-screen-only {
    display: none !important;
  }

  .large-screen-only {
    display: flex !important;
  }
}

/* Extra large screens, TV  */
@media only screen and (min-width: 1200px) {
  .home-header,
  .home-section {
    max-width: 1024px;
  }

  .home-header {
    gap: 36px;
  }

  .home-section {
    gap: 64px;
  }

  .home-article {
    gap: 32px;
  }
}
