.discovery-section {
  min-height: 100vh;
  flex-direction: column;
  gap: 48px;
  margin-top: 0px;
}

.discovery-section-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-left: 16px;
  padding-right: 16px;

  margin-top: auto;

  max-width: 448px;
  text-align: center;
}

.discovery-header-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.discovery-header {
  opacity: 0;
  transform: scale(0.5);
  animation: fadeInScale 0.5s ease-in-out forwards;
}

.discovery-subheader {
  font-size: 12px;
  opacity: 0;
  transform: scale(0.5);
  animation: fadeInScale 0.5s ease-in-out forwards;
  animation-delay: 0.5s;
}

.discovery-cards-block {
  display: flex;
  gap: 24px;
}

.discovery-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  width: 80px;
  height: 120px;
  border-radius: 8px;
  background-image: linear-gradient(
    to right,
    #fdc43f 0%,
    #fff75e 45%,
    #fdc43f 85%
  );
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  opacity: 0;
  transform: scale(0.5);
  animation: fadeInScale 0.5s ease-in-out forwards;
}

.discovery-card:nth-child(1) {
  animation-delay: 0.5s;
}

.discovery-card:nth-child(2) {
  animation-delay: 0.75s;
}

.discovery-card:nth-child(3) {
  animation-delay: 1s;
}

.discovery-card-icon {
  font-size: 24px;
  color: #000000c0;
}

.discovery-content-text-block {
  display: flex;
  opacity: 0;
  transform: scale(0.5);
  animation: fadeInScale 0.5s ease-in-out forwards;
  animation-delay: 1s;
  position: relative;
  max-width: 240px;
}

.discovery-content-text {
  font-size: 16px;
  font-family: "Prata", sans-serif;
}

.discovery-content-text-thumbs-up {
  color: #fdc43f;
  font-size: 20px;
  position: absolute;
  top: 42%;
  right: -42px;
  cursor: pointer;
}

.discovery-content-text-thumbs-down {
  color: #fdc43f;
  font-size: 20px;
  position: absolute;
  top: 42%;
  left: -42px;
  cursor: pointer;
}

.discovery-content-source {
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;

  opacity: 0;
  transform: scale(0.5);
  animation: fadeInScale 0.5s ease-in-out forwards;
  animation-delay: 1.5s;
}

.discovery-content-source-text {
  font-size: 14px;
  font-family: "Prata", sans-serif;
}

.discovery-content-source-link {
  font-size: 14px;
  font-family: "Prata", sans-serif;
  background-image: linear-gradient(to right, #daddd8, #fafaff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  position: relative;
}

.discovery-content-source-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px;
  background-color: #daddd8;
}

.discovery-extra-subheader {
  font-size: 14px;
  opacity: 0;
  transform: scale(0.5);
  animation: fadeInScale 0.5s ease-in-out forwards;
  animation-delay: 1.5s;
}

.discovery-promo-codes-block {
  display: none;
  flex-direction: column;
  gap: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url("/public/background-image.webp");
  background-size: cover;
  background-repeat: repeat-y;
  border: #fff75e 1px solid;
  box-shadow: 2px 4px 8px rgba(255, 255, 255, 0.2); /* Add a shadow for depth */
  border-radius: 8px;
  padding: 36px 16px;
  width: 85%;
  max-width: 480px;
  z-index: 1000;
  opacity: 0;
  transform: scale(0.5);
  text-align: center;
}

.discovery-promo-codes-block.active {
  display: flex;
  position: fixed;
  animation: fadeInScale 0.5s ease-in-out forwards;
  animation-delay: 2s;
  transform: translate(-50%, -50%);
}

.discovery-promo-code-heading {
  letter-spacing: 2px;
}

.discovery-promo-codes-block-close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: transparent;
  border: none;
  color: #fff75e;
  font-size: 16px;
  cursor: pointer;
}

.discovery-promo-codes-list {
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 12px;
  list-style-type: none;
}

.discovery-promo-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #ffffffc0;
}

.discovery-promo-code-link {
  display: flex;
  align-items: center;
  gap: 4px;
}

.discovery-promo-code-button {
  padding: 4px 8px;
  font-size: 12px;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.discovery-footer-block {
  margin-top: auto;
}

.discovery-error {
  color: #ff0000;
  font-size: 16px;
}

.discovery-warning {
  font-size: 16px;
  font-family: '"Prata", sans-serif';
  color: #dc143c;
}

/* MEDIA QUERIES */

/* iPads, Tablets */
@media only screen and (min-width: 480px) {
  .discovery-content-text-block {
    max-width: 320px;
  }

  .discovery-content-text {
    font-size: 20px;
  }

  .discovery-cards-block {
    gap: 32px;
  }

  .discovery-card {
    width: 100px;
    height: 140px;
  }

  .discovery-promo-codes-block {
    gap: 28px;
  }

  .discovery-promo-codes-list {
    gap: 16px;
  }

  .discovery-promo-code {
    gap: 10px;
  }

  .discovery-promo-code-button {
    padding: 6px 12px;
    font-size: 14px;
  }
}

/* Small screens and laptops */
@media only screen and (min-width: 768px) {
  .discovery-content-text-block {
    max-width: unset;
  }

  .discovery-card {
    width: 112px;
    height: 154px;
  }

  .discovery-promo-codes-block {
    gap: 32px;
    max-width: 540px;
  }

  .discovery-promo-codes-list {
    gap: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .discovery-promo-codes-block {
    gap: 32px;
    max-width: 600px;
  }
}
