.nav {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 36px;
  padding-top: 24px;
  width: 100%;
}

.nav-logo {
  width: 128px;
}

.nav-links-list {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  flex-wrap: wrap;
}

.nav-link {
  font-size: 13px;
  text-decoration: none;
  background-image: linear-gradient(to right, #fdc43f, #fff75e);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-transform: uppercase;
}

.nav-link::after {
  content: "";
  display: block;
  margin-top: 1px;
  width: 0;
  height: 1px;
  background: #fff75e;
  transition: width 0.3s;
}

.nav-link:hover::after {
  width: 100%;
}

.nav-button {
  font-size: 16px;
  line-height: 1.6;
  padding: 8px 16px;
}

/* MEDIA QUERIES */

/* iPads, Tablets */
@media only screen and (min-width: 480px) {
  .nav-links-list {
    gap: 24px;
  }

  .nav-link {
    font-size: 16px;
  }

  .nav-link::after {
    height: 1px;
  }
}

/* Small screens and laptops */
@media only screen and (min-width: 768px) {
  .nav {
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }
}

/* Regular laptops, desktops, large screens */
@media only screen and (min-width: 1024px) {
  .nav-links-list {
    gap: 48px;
  }

  .nav-link {
    font-size: 20px;
  }

  .nav-button {
    font-size: 20px;
    padding: 12px 24px;
  }
}

/* Extra large screens, TV  */
@media only screen and (min-width: 1200px) {
  .nav-sticky-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
}

.nav-centered {
  justify-content: center;
}
