/*
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128 / 160 / 192 / 224 / 256 / 320 / 384 / 448 / 512 / 640 / 768 / 896 / 1024 / 1152 / 1280 / 1408 / 1536 / 1664 / 1792 / 1920

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* general styles */
html {
  scroll-behavior: smooth;
}

body {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url("/public/background-image.webp");
  background-size: cover;
  background-repeat: repeat-y;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  background-image: linear-gradient(to right, #fdc43f, #fff75e);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  line-height: 1.25;

  font-family: "Prata", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  background-image: linear-gradient(to right, #daddd8, #fafaff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  line-height: 1.6;

  font-size: 14px;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

button,
.button-link {
  background-image: linear-gradient(
    to right,
    #fdc43f 0%,
    #fff75e 45%,
    #fdc43f 85%
  );
  color: rgba(0, 0, 0, 0.7);

  font-family: "Prata", sans-serif;
  font-weight: 400;
  font-style: normal;

  cursor: pointer;

  border: 2px solid rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}

.button-link {
  text-decoration: none;
  text-align: center;
}

button:hover,
.button-link:hover {
  transform: scale(1.05);
}

img {
  border-radius: 8px;
}

.badge {
  display: flex;
  align-items: center;
  gap: 4px;

  background-color: #fff75e;
  color: rgba(0, 0, 0, 0.7);

  font-family: "Prata", sans-serif;
  font-size: 10px;
  font-weight: bold;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  width: max-content;
}

.keyword {
  font-weight: bold;
  color: #f9e9e9;
}

.keyword:hover {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  transform: scale(1.1);
}

.streched {
  align-self: stretch;
}

.vertical-alignment {
  display: flex;
  align-items: center;
  gap: 4px;
}

.neutral-color {
  color: #daddd8;
}

.width-100 {
  width: 100%;
}

/* section styles */
.section {
  margin-top: 128px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;

  padding-left: 36px;
  padding-right: 36px;
}

.section-block {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.section-subtext {
  font-size: 12px;
  text-align: justify;
}

.section-limited-block {
  max-width: 384px;
}

.section-block-badge-paragraph {
  display: flex;
  gap: 12px;
  align-items: center;
}

.section-next-link {
  align-self: end;
  background-color: #fdc43f;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 2px;
}

.general-subtext {
  font-size: 12px;
  font-style: italic;
}

.small-screen-content {
  display: initial;
}

.large-screen-content {
  display: none;
}

/* heading section navigation styles */
.heading-section-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.heading-section-nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  background-color: #fdc43f;
  color: rgba(0, 0, 0, 0.7);

  text-align: center;

  font-size: 10px;
  font-family: "Prata", sans-serif;
  font-weight: 400;

  padding: 4px 24px;

  cursor: pointer;

  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.heading-section-nav-link-text {
  display: none;
}

.heading-section-nav > .heading-section-nav-link:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.heading-section-nav > .heading-section-nav-link:last-child {
  border-right: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.heading-section-nav-link:hover {
  background-color: #fff75e;
}

/* form styles */
.order-section-block {
  gap: 24px;
  padding-bottom: 64px;
  padding-left: 36px;
  padding-right: 36px;
  max-width: 384px;
}

.form-section-block {
  gap: 24px;
  padding-bottom: 64px;
  padding-left: 36px;
  padding-right: 36px;
  max-width: 384px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
}

.form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;

  color: #daddd8;
  line-height: 1.6;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

form > .form-row:last-child {
  padding-top: 24px;
}

.form-row-col {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.form-row-col > input {
  width: 220px;
}

.form-input-confirmation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.form-input-confirmation > label {
  width: 192px;
}

.form-price-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 220px;
}

.form-input-file {
  max-width: 192px;
}

.form-input-radio-button-div {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 227px;
}

.form-gift-variations {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  position: relative;
}

.form-gift-variations-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-gift-variation {
  width: 100px;
  height: 100px;
}

.form-gift-selected-variation {
  border: 1px solid #fff75e;
}

.form-submit-button {
  font-size: 16px;
  line-height: 1.6;
  padding: 8px 16px;
}

.form-error-message {
  color: #ff0000;
  font-size: 12px;
}

.access-form-error-message {
  color: #ff0000;
  font-size: 12px;
  text-align: justify;
}

.form-success-message {
  color: #fdc43f;
  font-size: 14px;
}

.form-label-asterisk {
  color: #bf0603;
  align-self: start;
}

.form-input-button {
  border-radius: 0;
  border: none;
  padding: 5px;
}

.form-input-button:hover {
  background-color: #fdc43f;
  transform: none;
}

.form-large-input {
  width: 192px;
  height: 28px;
  font-size: 16px;
}

.form-large-input:disabled {
  background-color: #daddd8;
}

/* images */
.heading-section-image {
  width: 256px;
}

.instructions-image {
  width: 256px;
}

/* Access page styles */
.access-form-section-block {
  margin-top: auto;
  gap: 32px;
  padding-bottom: 0px;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 448px;
}

/* MEDIA QUERIES */

/* iPads, Tablets */
@media only screen and (min-width: 480px) {
  .heading-section-nav-link {
    gap: 8px;
    font-size: 14px;
    padding: 8px 16px;
  }

  .heading-section-nav-link-text {
    display: initial;
  }

  form {
    gap: 20px;
  }

  .form-row {
    font-size: 14px;
    gap: 20px;
  }

  .form-input-confirmation > label {
    width: 256px;
  }

  .form-price-row {
    width: 280px;
  }

  .form-input-file {
    max-width: 320px;
  }

  .form-gift-variation {
    width: 128px;
    height: 128px;
  }

  .badge {
    font-size: 12px;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 30px;
  }

  p {
    font-size: 16px;
  }

  .section-limited-block {
    max-width: 448px;
  }

  .section-subtext {
    font-size: 14px;
  }

  .order-section-block {
    max-width: 448px;
  }

  .form-section-block {
    max-width: 448px;
  }

  .form-gift-variation {
    width: 127px;
    height: 127px;
  }

  .access-form-section-block {
    padding-left: 36px;
    padding-right: 36px;
  }
}

/* Small screens and laptops */
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 36px;
  }

  p {
    font-size: 18px;
  }

  .badge {
    font-size: 14px;
  }

  .section {
    margin-top: 192px;
    padding-left: 96px;
    padding-right: 96px;
  }

  .section-subtext {
    font-size: 16px;
  }

  .heading-section-nav-link {
    font-size: 14px;
  }

  form > .form-row:last-child {
    padding-top: 32px;
  }

  form {
    gap: 24px;
  }

  .form-row {
    font-size: 16px;
    gap: 24px;
  }

  .form-input-confirmation > label {
    width: 512px;
  }

  .form-price-row {
    width: 540px;
  }

  .form-input-file {
    max-width: 448px;
  }

  .form-gift-variations {
    grid-template-columns: repeat(3, 1fr);
  }

  .section-limited-block {
    max-width: 640px;
  }

  .order-section-block {
    max-width: 640px;
  }

  .form-section-block {
    max-width: 640px;
  }

  .form-gift-variation {
    width: 149px;
    height: 149px;
  }
}

/* Regular laptops, desktops, large screens */
@media only screen and (min-width: 1024px) {
  .order-section-block {
    max-width: 680px;
  }

  .form-section-block {
    max-width: 680px;
  }

  .form-gift-variations {
    gap: 30px;
  }

  .form-row {
    gap: 64px;
  }

  .form-submit-button {
    font-size: 18px;
    padding: 12px 24px;
  }

  .form-gift-selected-variation {
    border: 3px solid #fff75e;
  }
}

@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 52px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 36px;
  }

  h4 {
    font-size: 30px;
  }

  p {
    font-size: 20px;
  }

  .badge {
    font-size: 14px;
  }

  .large-screen-content {
    display: initial;
  }

  .small-screen-content {
    display: none;
  }

  .section {
    flex-direction: row;
    gap: 32px;

    min-height: 100vh;
    margin-top: 0px;
    padding-left: 192px;
    padding-right: 192px;
  }

  .section-limited-block {
    max-width: 512px;
  }

  .order-section-block {
    padding-bottom: 0px;
  }

  .form-section-block {
    padding-bottom: 0px;
  }

  .form-row {
    gap: 48px;
  }
}
