.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.carousel-arrow {
  border: none;
  cursor: pointer;
  color: #fff75e;
}

.carousel-nav {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.carousel-nav-tab {
  width: 16px;
  height: 4px;
  background-color: #ccc;
  border-radius: 2px;
  transition: background-color 0.5s ease-in-out;
  cursor: pointer;
}

.carousel-nav-tab.active {
  background-color: #fdc43f;
}

.carousel-image-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.carousel-image-list {
  list-style-type: none;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  max-height: 320px;
}

.carousel-image.active {
  opacity: 1;
  position: relative;
}

@media screen and (min-width: 480px) {
  .carousel-nav-tab {
    width: 24px;
  }

  .carousel-image {
    max-height: 384px;
  }
}

@media screen and (min-width: 768px) {
  .carousel-image {
    max-height: 448px;
  }
}
